/* selecting each and every element on the page and applying reset to it */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  /*border box inherited from body*/
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  /*easier to calculate props: total  width and height will include paddings */
  box-sizing: border-box;
}

/*
    Below is part of reset.css
    http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
*/
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

:root {
  --color-primary: #0C0C0C;
  --color-white: #FFFFFF;
}

.gm-style-mtc,
.gm-svpc,
.gm-style-cc {
  display: none;
}

.pointer {
  cursor: pointer;
}

.mobile {
  display: flex !important;
}

.desktop {
  display: none !important;
}

.bold {
  font-weight: 800;
}

.header {
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 120%;
  text-align: center;
}

.jump {
  transform-origin: 50% 50%;
  animation: jump 0.5s linear alternate infinite;
}

#circle-roll {
  animation: animateRoll 1.7s linear 0.3s infinite;
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, 30%, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes animateRoll {
  50% {
    cy: 37;
    opacity: 0.1;
  }
}
@media only screen and (min-width: 400px) {
  .header {
    font-size: 2.8rem;
  }
}
@media only screen and (min-width: 900px) {
  .mobile {
    display: none !important;
  }
  .desktop {
    display: flex !important;
  }
  .header {
    font-size: 4.8rem;
  }
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  src: local("Gilroy"), url("/src/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 300;
  src: local("Gilroy"), url("/src/fonts/Gilroy-UltraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy"), url("/src/fonts/Gilroy-Regular.ttf") format("truetype");
}
body {
  box-sizing: border-box;
  font-family: "Gilroy";
  line-height: 1.92rem;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: var(--color-primary);
  color: var(--color-white);
}

html {
  scroll-snap-type: y mandatory;
  scroll-padding-top: 50px;
}

.hero,
#about-us,
#record-studio,
#course-dj,
#course-production,
#rent,
#contact-us {
  height: calc(100vh - 50px);
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

@media only screen and (max-width: 1080px) {
  html {
    scroll-snap-type: none;
    scroll-padding-top: none;
  }
  .hero,
  #about-us,
  #record-studio,
  #course-dj,
  #course-production,
  #rent,
  #contact-us {
    height: 100%;
    scroll-snap-align: none;
  }
}
.cards {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  max-width: 411px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 3.2rem 2.4rem;
}
.card:hover .card__img {
  -webkit-filter: unset;
  filter: unset;
}
.card:hover {
  border: 1px solid var(--color-white);
}
.card__img {
  width: 100%;
  height: auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.card__title {
  margin-bottom: 1.6rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  line-height: 120%;
}
.card__price {
  margin: 7px 0 9px 0;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 800;
}
.card__list {
  padding: 3.2rem 0;
  list-style-type: disc;
  list-style-position: inside;
}
.card__list-item {
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.72);
  white-space: pre-wrap;
}
.card__btn {
  width: 100% !important;
  max-width: 58rem;
  margin-top: auto;
}

@media only screen and (min-width: 900px) {
  .cards {
    flex-direction: row;
    gap: 2.4rem;
  }
  .card {
    height: 100%;
    max-width: 62.8rem;
  }
  .card__title {
    margin-top: 20px;
    font-size: 2.4rem;
  }
  .card__price {
    margin: 10px 0 20px 0;
  }
  .card__list-item {
    font-size: 1.6rem;
    font-weight: 300;
  }
  .card__btn {
    visibility: hidden;
    bottom: 2rem;
  }
  .card:hover .card__btn {
    visibility: inherit !important;
  }
}
@media only screen and (min-width: 991px), screen and (max-height: 1000px), screen and (max-width: 1080px) {
  .card__title {
    margin-top: 10px;
  }
  .card__price {
    margin-bottom: 10px;
  }
  .card__list {
    padding: 1rem 2rem;
  }
}
@media only screen and (min-height: 900px), screen and (min-width: 1000px) {
  .cards.desktop {
    height: 100%;
    flex: 1 0;
    margin-top: 2%;
    padding: 3% 0;
  }
  .card__list-item {
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
  }
}
@media only screen and (max-height: 850px), screen and (min-width: 1000px) {
  .record-studio__inner .cards.desktop {
    flex: 1 1 60%;
    padding: 0;
    padding-top: 3%;
    max-height: 600px;
  }
  .card__list-item {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .card__img {
    max-height: 150px;
    object-fit: cover;
  }
}
@media only screen and (min-height: 850px), screen and (min-width: 1000px) {
  .card__list-item {
    font-size: 1.6rem;
  }
}
.carousel {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
}
.carousel a {
  display: none;
}

.carousell-wrapper:before {
  content: "";
  position: absolute;
  inset: 0;
  animation: pulse 2.5s infinite;
}

.carousell-wrapper.loaded:before {
  content: none;
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.05);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
.carousell-wrapper {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
}
.carousell-wrapper img {
  aspect-ratio: 1.5/1;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  height: 100%;
}

.carousell-wrapper.loaded img {
  opacity: 1;
}

.inner {
  white-space: nowrap;
  transition: transform 0.5s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 0;
  width: 100%;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > div {
  width: 8px;
  height: 8px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.24);
  border-radius: 5px;
  margin: 5px;
}

.indicators > div.active {
  background: rgba(255, 255, 255, 0.72);
  border: 1px solid rgba(255, 255, 255, 0.72);
  color: #fff;
}

.carousell,
.carousell-main {
  height: 100%;
}

@media only screen and (min-width: 991px) {
  .carousel-item {
    height: 393px;
    margin-bottom: 3rem;
  }
  a {
    display: block;
  }
}
#fb:hover #fb-rect {
  fill: white;
}

#fb:hover #fb-path {
  stroke: black;
  fill: black;
}

#ig:hover #ig-rect {
  fill: white;
}

#ig:hover #ig-vector1 {
  fill: black;
}

#ig:hover #ig-vector2 {
  fill: black;
}

#ig:hover #ig-vector3 {
  fill: black;
}

#tk:hover #tk-path {
  fill: black;
}

#tk:hover #tk-rect {
  fill: white;
}

#tg:hover #tg-path {
  fill: black;
}

#tg:hover #tg-rect {
  fill: white;
}

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 8rem 0;
  background: var(--color-primary);
}
.about-us__text {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.68rem;
  padding: 0 3.2rem;
}
.about-us__text:first-of-type {
  margin-bottom: 1.4rem;
}
.about-us__text:last-of-type {
  margin-bottom: 6.4rem;
}

@media only screen and (min-width: 991px) {
  .about-us {
    max-width: 84.6rem;
  }
  .about-us__text {
    width: 100%;
    font-size: 2rem;
    line-height: 120%;
    font-weight: 300;
    padding: 0;
  }
}
#contact-us,
#contact-us-snapless {
  width: 100%;
}

#contact-us {
  display: flex;
  flex-direction: column;
}

.contact-us {
  gap: 2rem;
  width: 100%;
  padding: 8rem 3.2rem;
}
.contact-us .contact-us-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-areas: "header" "links" "social";
}
.contact-us__header {
  grid-area: header;
  text-align: center;
}
.contact-us__links-container {
  grid-area: links;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 3rem;
}
.contact-us__link {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  left: auto;
  padding-left: 3rem;
}
.contact-us__link:nth-child(3) div {
  display: flex;
  flex-direction: row;
}
.contact-us__link:nth-child(3) span {
  padding-top: 2px;
  padding-right: 4px;
}
.contact-us__text {
  color: var(--color-white);
  font-size: 1.4rem;
  font-weight: 300;
}
.contact-us__social-media-container {
  grid-area: social;
  display: flex;
  gap: 2.4rem;
  margin-top: 4rem;
  margin-bottom: 4.8rem;
  justify-content: center;
}
.contact-us__map {
  grid-area: map;
  height: 213px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-us__question {
  grid-area: map;
  color: var(--color-white);
  margin-bottom: 2.4rem;
  font-weight: 300;
}
.contact-us__btn {
  grid-area: btn;
}

@media only screen and (min-width: 990px) {
  .contact-us__header {
    padding-left: 3rem;
  }
  .contact-us__social-media-container {
    justify-content: flex-start;
    padding-left: 3rem;
  }
  .contact-us__link:nth-child(3) div {
    flex-direction: column;
  }
}
@media only screen and (min-width: 991px) {
  .contact-us {
    grid-template-columns: 1fr;
    grid-template-areas: "header map" "links map" "social map" "question map" "btn map";
    padding: 0;
    padding-left: 8rem;
  }
  .contact-us__header {
    text-align: left;
    font-size: 4.8rem;
    font-weight: 800;
    margin-top: 6rem;
  }
  .contact-us__text {
    font-size: 1.6rem;
  }
  .contact-us__map {
    width: 100%;
    height: 100%;
  }
  .contact-us__btn {
    margin-bottom: 8rem;
  }
  .contact-us__link:nth-child(3) div {
    flex-direction: column;
  }
}
@media only screen and (min-width: 991px), screen and (max-height: 1000px), screen and (max-width: 1080px) {
  .contact-us {
    padding: 0;
    gap: 1%;
    flex: 1 0;
  }
  .contact-us__header {
    font-size: 3.8rem;
    font-weight: 800;
    margin-top: calc(3rem + 1%);
  }
  .contact-us__text {
    font-size: 1.4rem;
  }
  .contact-us__sub-header {
    margin-bottom: 15px;
  }
  .contact-us__btn {
    margin-bottom: 4rem;
    padding: 2rem 3rem !important;
  }
}
@media only screen and (max-width: 990px) {
  #contact-us-map {
    height: 20rem;
    margin-bottom: 8rem;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1080px) {
  #contact-us-map {
    height: 30rem;
    margin-bottom: 8rem;
  }
}
@media only screen and (min-width: 1080px) {
  .contact-us {
    display: flex;
  }
  .contact-us__header {
    margin-top: calc(3rem + 1%);
    padding-left: 0;
    text-align: left;
    font-size: 4.8rem;
    font-weight: 800;
  }
  .contact-us__text {
    font-size: 2rem;
  }
  .contact-us__link {
    padding-left: 0;
  }
  .contact-us__social-media-container {
    padding-left: 0;
    justify-content: flex-start;
  }
}
@media only screen and (max-height: 850px), screen and (min-width: 1000px) {
  .contact-us {
    padding: 0;
    justify-items: start;
    height: max-content;
  }
  .contact-us__header {
    font-size: 3.8rem;
    font-weight: 800;
    margin: 8rem 0 4.8rem 0;
  }
  .contact-us__links-container {
    margin-top: 0rem;
  }
  .contact-us__text {
    font-size: 1.4rem;
  }
  .contact-us__sub-header {
    margin-bottom: 15px;
  }
  .contact-us__map {
    width: 100%;
    height: auto;
  }
  .contact-us__btn {
    margin-bottom: 4rem;
    padding: 2rem 3rem !important;
  }
}
@media only screen and (min-width: 1000px) {
  .contact-us {
    flex-direction: row;
  }
  .contact-us .contact-us-container {
    padding-left: 8rem;
    justify-items: left;
  }
  .contact-us__text {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 1440px) and (min-height: 1024px) {
  .contact-us__header {
    margin-top: 14rem;
  }
  .contact-us__links-container {
    gap: 2.1rem;
  }
  .contact-us__social-media-container {
    margin-top: 12rem;
    margin-bottom: 14rem;
  }
  .contact-us__map {
    height: 69.2rem;
  }
}
.course-dj {
  width: 100%;
  padding: 8rem 0;
}
.course-dj__header {
  padding: 0 3.2rem;
}
.course-dj__header--desktop {
  display: none;
}
.course-dj__img {
  margin-top: 4rem;
  width: 100%;
  height: auto;
}
.course-dj__outer-text-container {
  margin-top: 4rem;
  padding: 0 3.2rem;
}
.course-dj__text-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.course-dj__text {
  font-size: 1.6rem;
  font-weight: 300;
}
.course-dj__text:first-of-type {
  margin-bottom: 1.6rem;
}
.course-dj__text:last-of-type {
  margin-bottom: 4rem;
}
.course-dj__button {
  margin: 0 3.2rem;
}
.course-dj__btn--desktop {
  display: none !important;
}
.course-dj__btn--mobile {
  display: flex;
}

@media only screen and (min-width: 991px) {
  .course-dj {
    position: relative;
    margin-bottom: 30rem;
    padding: 2rem 80px;
    height: 100%;
  }
  .course-dj__text {
    font-size: 18px;
  }
  .course-dj__inner {
    top: calc((100% - 577px) / 2);
    height: 100%;
  }
  .course-dj__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
@media only screen and (min-width: 1080px) {
  .course-dj {
    /* height: 100vh; */
    position: relative;
    margin-bottom: 30rem;
  }
  .course-dj__wrapper {
    position: relative;
    display: block;
  }
  .course-dj__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0rem 8rem;
    left: 0;
  }
  .course-dj__header {
    font-size: 48px;
    line-height: 120%;
  }
  .course-dj__header--mobile {
    display: none;
  }
  .course-dj__header--desktop {
    display: block;
  }
  .course-dj__img {
    position: absolute;
    width: 858px;
    height: 577px;
    margin-top: 0;
  }
  .course-dj__outer-text-container {
    position: absolute;
    top: 40px;
    right: 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0;
    padding: 0;
    max-width: 60rem;
  }
  .course-dj__text-container {
    padding: 24px;
    background-color: var(--color-primary);
  }
  .course-dj__text {
    line-height: 130%;
  }
  .course-dj__text:last-of-type {
    margin-bottom: 0;
  }
  .course-dj__btn {
    margin-right: 5rem;
    width: 100%;
  }
  .course-dj__btn--desktop {
    display: flex !important;
  }
  .course-dj__btn--mobile {
    display: none !important;
  }
}
@media only screen and (min-width: 1440px) {
  .course-dj__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0rem calc((100% - 1300px) / 2);
  }
  .course-dj__img {
    position: absolute;
    left: 0rem;
    width: 858px;
    height: 577px;
  }
  .course-dj__outer-text-container {
    position: absolute;
    top: 40px;
    right: 8rem;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
  }
}
@media only screen and (min-width: 1080px) {
  .course-dj__img {
    max-height: 100%;
  }
}
@media only screen and (max-width: 764px) {
  .course-dj__outer-text-container {
    margin-top: 2rem;
  }
  .course-dj__img {
    margin-top: 2rem;
  }
}
.course-production {
  width: 100%;
}
.course-production h1 {
  z-index: 10;
  position: relative;
}
.course-production__header {
  text-align: center;
  padding: 0 3.2rem;
  padding-top: 4rem;
}
.course-production__img {
  margin-top: 4rem;
  width: 100%;
  height: auto;
}
.course-production__img--desktop {
  display: none;
}
.course-production__outer-text-container {
  margin-top: 4rem;
  padding: 0 3.2rem;
}
.course-production__text-container {
  display: flex;
  flex-direction: column;
}
.course-production__text {
  font-size: 1.6rem;
  font-weight: 300;
  font-size: 20px;
  line-height: 130%;
  display: flex;
  align-items: center;
}
.course-production__text:first-of-type {
  margin-bottom: 1.6rem;
}
.course-production__text:last-of-type {
  margin-bottom: 4rem;
}
.course-production__btn--desktop {
  display: none !important;
}

@media only screen and (min-width: 991px) {
  .course-production {
    position: relative;
    margin-bottom: 30rem;
    padding: 2rem 80px;
    height: 100%;
  }
  .course-production .coourse-production__header {
    padding: calc((100% - 800px) / 2);
  }
  .course-production__inner {
    top: calc((100% - 577px) / 2);
    height: 100%;
  }
  .course-production__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .course-production__img {
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
  }
  .course-production__text {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1080px) {
  .course-production {
    display: block;
    position: relative;
    padding: 8rem 0;
  }
  .course-production__wrapper {
    display: block;
  }
  .course-production__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0rem 8rem;
  }
  .course-production__img {
    position: absolute;
    max-height: 100%;
    margin-top: 4rem;
    right: 8rem;
    width: 858px;
    height: 577px;
  }
  .course-production__img--mobile {
    display: none;
  }
  .course-production__img--desktop {
    display: block;
  }
  .course-production__outer-text-container {
    position: absolute;
    top: 20rem;
    left: 10rem;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0;
    max-width: 60rem;
  }
  .course-production__text-container {
    padding: 2.4rem;
    background-color: var(--color-primary);
  }
  .course-production__text:last-of-type {
    margin-bottom: 0;
  }
  .course-production__btn--mobile {
    display: none !important;
  }
  .course-production__btn--desktop {
    display: flex !important;
  }
}
@media only screen and (min-width: 1440px) {
  .course-production__wrapper {
    position: relative;
  }
  .course-production__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0rem calc((100% - 1300px) / 2);
  }
  .course-production__img {
    position: absolute;
    right: 0rem;
    width: 858px;
    height: 577px;
  }
  .course-production__outer-text-container {
    position: absolute;
    top: 20rem;
    left: 0rem;
    right: 50%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0;
  }
}
@media only screen and (min-width: 991px) and (max-height: 1000px) {
  .course-production__img {
    max-height: 300px;
    margin-bottom: 4%;
  }
  .course-production__outer-text-container {
    margin-top: 1.2rem;
  }
  .course-production__text:last-of-type {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1080px) {
  .course-production {
    padding-top: 2rem;
  }
  .course-production__img {
    max-height: 577px;
    margin-bottom: 0;
  }
}
@media only screen and (max-height: 850px) and (min-width: 1000px) {
  .course-production__img {
    margin-bottom: 0;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 3.2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}
.footer__link {
  color: var(--color-white);
  font-size: 1.4rem;
}
.footer__link:first-child {
  margin-bottom: 2.4rem;
}

@media only screen and (min-width: 991px) {
  .footer {
    flex-direction: row;
    padding: 5rem 8rem;
  }
  .footer__link:first-child {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1000px), (min-height: 700px) and (max-height: 850px) {
  .footer {
    padding: 4rem 3.2rem;
  }
}
@media only screen and (min-width: 1080px) {
  .footer {
    background-color: black;
  }
}
@media only screen and (min-width: 1440px) and (min-height: 1024px) {
  .footer {
    flex-direction: row;
    padding: 5rem 8rem;
  }
}
.gift-certificate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url("/src/images/png/bg-gift-certificate.png");
  background-position: 50% 25%;
  background-repeat: no-repeat;
  background-size: cover;
}
.gift-certificate__bgOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(7, 7, 7, 0.25);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(10px);
}
.gift-certificate__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 128rem;
  padding: 8rem 3.2rem;
}
.gift-certificate__content-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gift-certificate__content {
  display: flex;
  flex-direction: column;
}
.gift-certificate__img {
  max-width: 8.5rem;
}
.gift-certificate__header {
  margin-top: 4rem;
  margin-bottom: 2.4rem;
}
.gift-certificate__text {
  margin-left: 3rem;
  margin-right: 1rem;
  margin-bottom: 4rem;
  font-size: 1.6rem;
  font-weight: 300;
}
.gift-certificate__btn-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2.4rem;
}
.gift-certificate a {
  width: 100%;
}

@supports (background-image: url("/src/images/webp/bg-gift-certificate.webp")) {
  .gift-certificate {
    background-image: url("/src/images/webp/bg-gift-certificate.webp");
  }
}
@media only screen and (min-width: 991px) {
  .gift-certificate__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8rem 6rem;
  }
  .gift-certificate__content-outer {
    flex-direction: row;
    gap: 2.4rem;
  }
  .gift-certificate__content {
    align-items: center;
    gap: 1.6rem;
  }
  .gift-certificate__header {
    margin-top: 0;
    margin-bottom: 0;
  }
  .gift-certificate__text {
    margin-bottom: 0;
  }
  .gift-certificate__btn-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
  }
  .gift-certificate a {
    width: auto;
  }
}
@media only screen and (min-width: 991px) {
  .gift-certificate__content {
    align-items: flex-start;
  }
  .gift-certificate__text {
    margin-left: 0;
  }
  .gift-certificate__bgOverlay {
    width: 100%;
  }
  .gift-certificate__inner {
    padding: 4rem 3rem;
  }
  .gift-certificate__btn-container div {
    padding: 1.8rem;
  }
}
@media only screen and (min-width: 1240px) {
  .gift-certificate__inner {
    flex-direction: row;
    align-items: center;
  }
  .gift-certificate__btn-container div {
    padding: 2.6rem 4rem;
  }
}
.main-header {
  display: flex;
  width: 100%;
  position: absolute;
  background: rgba(12, 12, 12, 0.94);
  z-index: 100;
}

.navigation-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  height: 7.2rem;
}

.navigation {
  display: none;
  padding: 16px 10px;
}
.navigation__arrow-link {
  height: 4rem;
}

.locale {
  display: flex;
  justify-content: center;
  align-items: center;
}
.locale__current-language {
  font-size: 1.4rem;
}

.mobile-locale-switcher {
  display: flex;
  gap: 0.8rem;
  margin-top: 8rem;
  font-size: 14px;
  line-height: 20px;
}
.mobile-locale-switcher__locale {
  color: rgba(255, 255, 255, 0.4);
  background: transparent;
  border: none;
}
.mobile-locale-switcher__locale--active {
  color: var(--color-white);
}
.mobile-locale-switcher__pipe {
  color: rgba(255, 255, 255, 0.4);
}

@media only screen and (min-width: 400px) {
  .header {
    font-size: 2.8rem;
  }
}
@media only screen and (min-width: 991px) {
  .header {
    font-size: 3.8rem;
  }
  .main-header {
    justify-content: center;
    position: fixed;
    top: 0;
    background: rgba(12, 12, 12, 0.94);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  .navigation-mobile {
    display: none;
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 128rem;
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav {
    display: flex;
    justify-content: space-around;
  }
  .nav__menu-item {
    font-size: 1.4rem;
  }
  .nav__menu-item-link._current {
    font-weight: 800;
    border-bottom: 1px solid #fff;
    transition: all 0.25s ease-in-out;
  }
  .nav__menu-item:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
  .nav__menu-item-link {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    padding-bottom: 0.4rem;
    color: var(--color-white);
    cursor: pointer;
    text-align: center;
    display: inline-block;
  }
  .nav__menu-item-link:hover {
    font-weight: 800;
    border-bottom: 1px solid #fff;
    transition: font-weight 0.25s ease-in-out, border-bottom 0.25s ease-in-out;
  }
  .mobile-locale-switcher {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1240px) {
  .header {
    font-size: 4.8rem;
  }
}
.hero {
  background-image: url("/src/images/png/bg-small.png");
  background-position: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-top);
}
.hero__scroll-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  bottom: 4rem;
}
.hero__scroll--desktop {
  display: none;
}
.hero #video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  pointer-events: none;
  object-fit: cover;
}

@media only screen and (min-width: 451px) {
  .hero {
    background-image: url("/src/images/png/bg-med.png");
  }
  @supports (background-image: url("/src/images/webp/bg-med.webp")) {
    .hero {
      background-image: url("/src/images/webp/bg-med.webp");
    }
  }
}
@media only screen and (min-width: 991px) {
  .hero {
    background-image: url("/src/images/png/bg.png");
    background-size: cover;
    background-position: unset;
    height: 100vh;
  }
  @supports (background-image: url("/src/images/webp/bg.webp")) {
    .hero {
      background-image: url("/src/images/webp/bg.webp");
    }
  }
  .hero__scroll-container {
    margin-bottom: 4rem;
    bottom: 4rem;
  }
  .hero__scroll--mobile {
    display: none;
  }
  .hero__scroll--desktop {
    display: block;
  }
}
.podcast {
  padding: 8rem 3.2rem;
}
.podcast__text {
  margin-top: 2.4rem;
  margin-bottom: 4rem;
  font-size: 1.6rem;
  font-weight: 300;
}
.podcast__btn-container {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 4rem;
}

.podcast-card {
  height: 175px;
}

.record-studio {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url("/src/images/png/bg-record-studio.png");
}
.record-studio__header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.record-studio__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8rem 3.2rem;
}
.record-studio__text {
  font-weight: 100;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  justify-content: center;
  color: #ffffff;
  text-align: center;
}
.record-studio__text:first-of-type {
  margin-top: 2.4rem;
  margin-bottom: 1.6rem;
}
.record-studio__text:last-of-type {
  margin-bottom: 4%;
}

@supports (background-image: url("/src/images/webp/bg-record-studio.webp")) {
  .record-studio {
    background-image: url("/src/images/webp/bg-record-studio.webp");
  }
}
@media only screen and (min-width: 747px) {
  .record-studio__text {
    align-items: center;
  }
}
@media only screen and (min-width: 991px) {
  .record-studio__text {
    max-width: 106.1rem;
    font-size: 18px;
  }
  .record-studio__inner {
    padding: 4rem 8rem;
    justify-content: space-around;
  }
  .record-studio__card {
    padding: 8% 2.4rem 2.4rem 2.4rem;
  }
}
@media only screen and (min-width: 1240px) {
  .record-studio__text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 764px) {
  .record-studio__inner {
    padding: 8rem 1rem;
  }
  .record-studio__card {
    margin: 0rem 2rem;
  }
  .record-studio__text {
    margin: 0rem 2rem;
  }
}
@media only screen and (max-height: 850px) and (min-width: 1000px) {
  .record-studio__text:first-of-type {
    margin-top: 1.4rem;
    margin-bottom: 0;
  }
  .record-studio__text:last-of-type {
    margin-bottom: 0.5rem;
  }
  .record-studio__inner {
    padding: 2rem 3rem;
    padding-top: 9rem;
    justify-content: space-around;
  }
  .record-studio__header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    justify-content: space-evenly;
    max-height: 250px;
  }
  .record-studio__inner .cards.desktop {
    flex: 1 1 60%;
    padding: 0;
    padding-top: 3%;
    max-height: 600px;
  }
  .record-studio__card {
    padding: 2.2rem 2rem;
    height: 350px;
    max-height: 440px;
  }
}
.rent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url("/src/images/png/bg-record-studio.png");
}
.rent__inner {
  width: 100%;
  height: 100%;
  padding: 8rem 1rem;
}
.rent__content {
  width: 100%;
}
.rent__card-container {
  margin-top: 4.8rem;
}
.rent__title {
  padding-bottom: 4.8rem;
}
.rent__cards {
  height: 605.05px;
}
.rent__card {
  width: 50%;
  height: 57rem;
}

@supports (background-image: url("/src/images/webp/bg-record-studio.webp")) {
  .rent {
    background-image: url("/src/images/webp/bg-record-studio.webp");
  }
}
@media only screen and (min-width: 991px) {
  .rent__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem;
  }
  .rent__content {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
  }
  .rent__card-container {
    margin-top: 4rem;
  }
  .rent__card {
    height: 62.4rem;
  }
  .rent__card-list {
    padding: 0;
  }
  .rent__header {
    margin-top: 3.2rem;
  }
}
@media only screen and (min-width: 1080px), screen and (max-height: 1000px) {
  .rent__card {
    height: 100%;
    max-height: 600px;
  }
}
@media only screen and (min-width: 991px) and (max-height: 1000px), screen and (max-width: 1080px) {
  .rent__card-container {
    margin-top: 2rem;
  }
  .rent__card {
    height: 540px;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (max-width: 764px) {
  .rent__card {
    margin: 0rem 2rem 2rem 2rem;
    width: 100%;
  }
}
@media only screen and (min-height: 990px), screen and (min-width: 1000px) {
  .rent__card {
    max-height: none;
  }
}
@media only screen and (min-width: 1000px) and (max-height: 850px) {
  .rent__card-container {
    margin-top: 0;
  }
  .rent__inner {
    justify-content: space-around;
  }
  .rent__card-container {
    max-height: 650px;
  }
  .rent__card-container {
    margin-top: 0;
  }
  .isUjPX {
    font-size: 1.5rem;
    padding: 2rem 3.5rem;
  }
}
.carousell {
  width: 600vw;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  margin-left: 20vw;
  max-height: 50vh;
}

.carousell-main {
  width: 98vw;
  overflow-x: hidden;
  position: relative;
}

.carousell-wrapper img {
  width: 60vw;
  height: 100%;
}

.carousell-main button {
  position: absolute;
  width: 45px;
  height: 35px;
  border: 1px solid gray;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #000;
  color: aliceblue;
  z-index: 25;
  cursor: pointer;
}

.nextt,
.prevv {
  top: 45%;
}

.prevv {
  left: 19vw;
  rotate: 180deg;
}

.nextt {
  right: 18vw;
}

.afterr,
.afterr2 {
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7019607843);
  z-index: 20;
  width: 20vw;
  max-height: 50vh;
}

.afterr {
  left: 0;
}

.afterr2 {
  right: 0;
}

.dots-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  gap: 10px;
  width: 200px;
  margin: 0 auto;
  bottom: 40px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.21);
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.399);
}

@media (max-width: 900px) {
  .carousell {
    width: 540vw;
    margin-left: 5vw;
  }
  .carousell-main {
    display: none;
  }
  .carousell-wrapper img {
    width: 90vw;
  }
  .afterr {
    width: 5vw;
  }
  .afterr2 {
    width: 5vw;
  }
  .prevv {
    left: 2vw;
  }
  .nextt {
    right: 1vw;
  }
  .nextt,
  .prevv {
    top: 40%;
  }
}
@media (max-width: 650px) {
  .prevv,
  .nextt {
    width: 30px !important;
    height: 25px !important;
  }
  .prevv img,
  .nextt img {
    width: 15px;
  }
}
@media (min-width: 1080px) {
  .nextt,
  .prevv {
    top: 40%;
  }
}
.course-dj-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 7.2rem;
  padding: 4rem 3.2rem;
  background: var(--color-primary);
}
.course-dj-page__top-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.course-dj-page__header {
  text-align: left;
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 120%;
}
.course-dj-page__first-line {
  margin-top: 2.4rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 120%;
}
.course-dj-page__sign-up {
  margin-top: 2.4rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 120%;
}
.course-dj-page__btn {
  margin-top: 2.4rem;
}
.course-dj-page__img {
  display: none;
}
.course-dj-page__img-mobile {
  width: 100%;
  height: 207.696px;
  object-fit: contain;
}
.course-dj-page__content {
  width: 100%;
  margin-top: 1.8rem;
}
.course-dj-page__sub-header {
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}
.course-dj-page__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2.4rem;
  list-style: disc;
}
.course-dj-page__list-item {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}
.course-dj-page__cards {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 4rem;
}
.course-dj-page__card {
  border: 1px solid var(--color-white);
}
.course-dj-page .course-page-card {
  display: flex;
  flex-direction: column;
  padding: 4.8rem 1.6rem;
}
.course-dj-page .course-page-card__header {
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  line-height: 120%;
}
.course-dj-page .course-page-card__description {
  margin-top: 0.8rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 120%;
}
.course-dj-page .course-page-card__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.4rem;
}
.course-dj-page .course-page-card__quantity {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}
.course-dj-page .course-page-card__price {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}

@media only screen and (min-width: 768px) {
  .course-dj-page__top-container {
    display: flex;
    flex-direction: column;
    max-width: 84.6rem;
  }
  .course-dj-page__img-mobile {
    display: none;
  }
  .course-dj-page__img {
    display: block;
    width: 100%;
    max-width: 1280px;
    margin-top: 7.2rem;
  }
  .course-dj-page__content {
    max-width: 84.6rem;
  }
  .course-dj-page__cards {
    flex-direction: row;
    gap: 2.4rem;
  }
  .course-dj-page__card {
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  .course-dj-page__img {
    width: 1280px;
  }
}
.terms-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 7.2rem;
  padding: 4rem 3.2rem;
  background: var(--color-primary);
}
.terms-page__top-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.terms-page__header {
  text-align: left;
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 120%;
}
.terms-page__sign-up {
  margin-top: 2.4rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 120%;
}
.terms-page__img {
  display: none;
}
.terms-page__img-mobile {
  width: 100%;
  object-fit: contain;
  margin-top: 2rem;
}
.terms-page__content {
  width: 100%;
}
.terms-page__list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 2.4rem;
  list-style: disc;
  list-style-type: none;
}
.terms-page__list-item {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

@media only screen and (min-width: 768px) {
  .terms-page__top-container {
    display: flex;
    flex-direction: column;
    max-width: 84.6rem;
  }
  .terms-page__img-mobile {
    display: none;
  }
  .terms-page__img {
    display: block;
    width: 100%;
    max-width: 1280px;
    margin-top: 2rem;
  }
  .terms-page__content {
    max-width: 84.6rem;
  }
}
@media only screen and (min-width: 1440px) {
  .terms-page__img {
    width: 1280px;
  }
}
#page-not-found {
  height: 100vh;
}

.page-not-found {
  width: 100%;
  padding: 8rem 0;
}
.page-not-found__header {
  padding: 0 3.2rem;
}
.page-not-found__header--desktop {
  display: none;
}
.page-not-found__img {
  margin-top: 4rem;
  width: 100%;
  height: auto;
}
.page-not-found__outer-text-container {
  margin-top: 4rem;
  padding: 0 3.2rem;
}
.page-not-found__text-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.page-not-found__text {
  font-size: 1.6rem;
  font-weight: 300;
}
.page-not-found__text:first-of-type {
  margin-bottom: 1.6rem;
}
.page-not-found__text:last-of-type {
  margin-bottom: 4rem;
}
.page-not-found__button {
  margin: 0 3.2rem;
}

@media only screen and (min-width: 991px) {
  #page-not-found {
    height: 100vh;
  }
  .page-not-found {
    position: relative;
    margin-bottom: 30rem;
    padding: 2rem 80px;
    height: 100%;
  }
  .page-not-found__text {
    font-size: 18px;
  }
  .page-not-found__inner {
    top: calc((100% - 577px) / 2);
    height: 100%;
  }
  .page-not-found__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
@media only screen and (min-width: 1080px) {
  #page-not-found {
    height: 100vh;
  }
  .page-not-found {
    /* height: 100vh; */
    position: relative;
    margin-bottom: 30rem;
  }
  .page-not-found__wrapper {
    position: relative;
    display: block;
  }
  .page-not-found__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0rem 8rem;
    left: 0;
  }
  .page-not-found__header {
    font-size: 48px;
    line-height: 120%;
  }
  .page-not-found__header--mobile {
    display: none;
  }
  .page-not-found__header--desktop {
    display: block;
  }
  .page-not-found__img {
    position: absolute;
    width: 858px;
    height: 577px;
    margin-top: 0;
  }
  .page-not-found__outer-text-container {
    position: absolute;
    top: 40px;
    right: 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0;
    padding: 0;
    max-width: 60rem;
  }
  .page-not-found__text-container {
    padding: 24px;
    background-color: var(--color-primary);
  }
  .page-not-found__text {
    line-height: 130%;
  }
  .page-not-found__text:last-of-type {
    margin-bottom: 0;
  }
  .page-not-found__btn {
    margin-right: 5rem;
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  #page-not-found {
    height: 100vh;
  }
  .page-not-found__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0rem calc((100% - 1300px) / 2);
  }
  .page-not-found__img {
    position: absolute;
    left: 0rem;
    width: 858px;
    height: 577px;
  }
  .page-not-found__outer-text-container {
    position: absolute;
    top: 40px;
    right: 8rem;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
  }
}
@media only screen and (min-width: 991px), screen and (max-height: 1000px), screen and (max-width: 1080px) {
  #page-not-found {
    height: 100vh;
  }
  .page-not-found__img {
    max-height: 45rem;
  }
  .page-not-found__wrapper {
    justify-content: center;
  }
}
@media only screen and (min-width: 1080px) {
  #page-not-found {
    height: 100vh;
    margin: 0;
  }
  .page-not-found__img {
    max-height: 100%;
  }
  .page-not-found__inner {
    max-height: 70vh;
  }
}
@media only screen and (max-width: 764px) {
  #page-not-found {
    height: 100vh;
  }
  .page-not-found__outer-text-container {
    margin-top: 2rem;
  }
  .page-not-found__img {
    margin-top: 2rem;
  }
}
